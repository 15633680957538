import React, { Component } from "react";
import Select from "react-select";
// import RangePicker from "react-range-picker";
import { DatePicker, Space } from "antd";
import moment from "moment";
// loader
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  particularReportDownload,
  asmDownloadReport,
} from "../../services/teamServices/team";
import { saveAs } from "file-saver";
import PrimaryHeader from "../header/PrimaryHeader";
import { handleErrorResponse } from "../../utils/Request";
import { getProfileDetails } from "../../services/userProfileServices/userProfile";

let ReportType = [
  {
    value: "collection",
    label: "Collection Reports",
    key: 1,
    name: "collection",
  },
  {
    value: "commitments",
    label: "Commitment Reports",
    key: 2,
    name: "commitments",
  },
  {
    value: "onboarding",
    label: "Newly Onboarded Customer Reports",
    key: 3,
    name: "newly_onboarded_customer",
  },
  {
    value: "orders",
    label: "Sales Order Reports",
    key: 4,
    name: "sales_order_reports",
  },
  {
    value: "customer-force-checkin",
    label: "Forced Checked in Reports",
    key: 5,
    name: "customer_force_checkin",
  },
  {
    value: "itemisedOrder",
    label: "SO Itemised Reports",
    key: 6,
    name: "so_itemised",
  },
  {
    value: "adoption-metrics",
    label: "Adoption Matrix Report",
    key: 7,
    name: "adoption_matrix",
  },
  {
    value: "asm-onboardingReport",
    label: "ASM verification report",
    key: 7,
    name: "ASM verification report",
  },
  {
    value: "delivery-verification",
    label: "Delivery verification Report",
    key: 8,
    name: "Delivery verification Report",
  },
];

const idToRemove = "asm-onboardingReport";
const reportFilterType = ReportType.filter((item) => item.value !== idToRemove);

//Only for Standalone
const StandaloneReport = () => {
  let list = [...ReportType];
  list.push({
    value: "tax-report",
    label: "Tax Report",
    key: 8,
    name: "Tax Report",
  });
   list.push({
     value: "product-margin",
     label: "Product Margin",
     key: 9,
     name: "Product Margin",
   });


  return list;
};

class DownloadReport extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      type: "",
      fromDate: "",
      toDate: "",
      errMessage: "",
      formName: "",
      customDate: [],
      reportName: "",
      asmApprovalRequired: false,
      userDetails: {},
      rangeStartDate: null,
    };
  }

  handleDownload = async () => {
    const { userDetails } = this.state;
    console.log(userDetails);
    const dynamic = [
      userDetails && userDetails.isStandalone,
      userDetails && userDetails.warehouse && userDetails.warehouse.isHybrid,
    ].every((ele) => ele === true);
    const companyCode = userDetails && userDetails.companyCode;
    console.log(`${dynamic},${companyCode}`);
    this.setState({
      loading: true,
    });
    let { type, fromDate, toDate } = this.state;
    let from = fromDate;
    let to = toDate;
    if (type == "asm-onboardingReport") {
      await asmDownloadReport(from, to)
        .then((res) => {
          console.log("Report is downloaded successfully", res);
          let blob = new Blob([res.data], { type: "text/csv" });
          saveAs(blob, `${this.state.formName}_${from}_${to}_reports.csv`);
          this.setState({
            loading: false,
            fromDate: "",
            toDate: "",
            type: "",
            formName: "",
            customDate: [],
            reportName: "",
          });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            message.error(err.response.data.message);
          } else if (err.response.status === 401) {
            message.error("Session expired. Please login and try again!");
          } else if (err.response.status === 409) {
            message.error(
              err.response.data.message === "Order Not Found !"
                ? "No Data Available in csv at this moment, please try again !"
                : err.response.data.message
            );
          } else {
            message.error(err);
          }
          this.setState({
            loading: false,
          });
          const { errorMessage, errors } = handleErrorResponse(err);
        });
    } else {
      await particularReportDownload(type, from, to, dynamic, companyCode)
        .then((res) => {
          console.log("Report is downloaded successfully", res);
          let blob = new Blob([res.data], { type: "text/csv" });
          saveAs(blob, `${this.state.formName}_${from}_${to}_reports.csv`);
          this.setState({
            loading: false,
            fromDate: "",
            toDate: "",
            type: "",
            formName: "",
            customDate: [],
            reportName: "",
          });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            message.error(err.response.data.message);
          } else if (err.response.status === 401) {
            message.error("Session expired. Please login and try again!");
          } else if (err.response.status === 409) {
            message.error(
              err.response.data.message === "Order Not Found !"
                ? "No Data Available in csv at this moment, please try again !"
                : err.response.data.message
            );
          }
          this.setState({
            loading: false,
          });
          const { errorMessage, errors } = handleErrorResponse(err);
        });
    }
  };

  checkFormValid = () => {
    let { type, fromDate, toDate } = this.state;

    let err = "";
    if (type == "") {
      err = "Please select a report type from dropdown";
      message.warning(err);
    } else if (
      fromDate == "" ||
      toDate == "" ||
      toDate == "Invalid date" ||
      fromDate == "Invalid date"
    ) {
      err = "Please select a valid date range from calendar";
      message.warning(err);
    } else {
      this.handleDownload();
    }
  };
  onDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };
  handleDistributorChange = (option) => {
    let value = option.value;
    console.log("handleDistributorChange option", option, value);
    this.setState({
      type: value,
      formName: option.name,
      reportName: option,
    });
  };

  selectDateRange = (dates) => {
    if (dates != null) {
      this.setState({
        fromDate: moment(dates[0], "DD/MM/YYYY").format("DD/MM/YYYY"),
        toDate: moment(dates[1], "DD/MM/YYYY").format("DD/MM/YYYY"),
        customDate: [
          dates ? moment(dates[0]._d) : "",

          dates ? moment(dates[1]._d) : "",
        ],
      });
    }
  };

  // to disable the future dates
  customeDisableDates = (current) => {
    const { rangeStartDate } = this.state;
    console.log("rangeStartDate in customeDisableDates:", rangeStartDate); // Log the range start date

    let today = moment(); // Get today's date
    let startDate = moment().subtract(6, 'months'); // Get the date 6 months before today

    // If no start date is selected, restrict to the last 6 months
    if (!rangeStartDate) {
        return current && (current < startDate || current > today.endOf('day'));
    }

    // Calculate the end date (30 days from rangeStartDate)
    let endDate = moment(rangeStartDate).add(30, 'days');

    // Disable dates outside of 30 days from rangeStartDate or dates after today
    return current && (current < rangeStartDate || current > endDate || current > today.endOf('day'));
};

  onCalendarChange = (dates) => {
    if (dates && dates.length > 0 && dates[0]) {
        console.log("onCalendarChange: Selected date:", dates[0]); // Log the selected date
        this.setState({ rangeStartDate: dates[0] }, () => {
            // Callback after state update
            console.log("Updated rangeStartDate:", this.state.rangeStartDate);
        });
    } else {
        // Handle the case where dates are cleared (null)
        this.setState({ rangeStartDate: null }, () => {
            console.log("Range start date has been cleared.");
        });
    }
};

  componentDidMount() {
    this.getUserDetails();
  }
  getUserDetails = async () => {
    await getProfileDetails()
      .then((res) => {
        this.setState({
          loading: false,
          asmApprovalRequired:
            res.data.data && res.data.data.asmApprovalRequired,
          userDetails: res.data.data,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };

  render() {
    //console.log(this.state.asmApprovalRequired)
    const { userDetails } = this.state;
    console.log(userDetails);
    const dynamic = [
      userDetails && userDetails.isStandalone,
      userDetails && userDetails.warehouse && userDetails.warehouse.isHybrid,
    ].every((ele) => ele === true);
    const companyCode = userDetails && userDetails.companyCode;
    console.log(`${dynamic},${companyCode}`);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { selectedDate, loading, customDate, reportName } = this.state;
    const { RangePicker } = DatePicker;
    const dateFormat = "DD/MM/YYYY";
    let today = new Date();
    let todays = new Date(today.setDate(today.getDate()));
    let date = moment(todays).format("DD/MM/YYYY");
    console.log("dynamic----------", dynamic);
    return (
      <div id="content">
        <PrimaryHeader title="Download Report" />
        {/* <div className="container-fluid my-0 mx-2 p-0"> */}
        <Spin indicator={antIcon} spinning={loading}>
          {/* <div className="row">
              <header className="header p-3 pb-1 header-border">
                <div className="d-flex border-btm">
                  <div className="mr-auto p-2 w-100 row">
                    <span className="col col-md-4 mr-0 download-report">
                      Download Report
                    </span>
                  </div>
                </div>
              </header>
            </div> */}
          <div className="report-dropdown-content">
            <p className="report-dropdown-name">Select Report :</p>
            <Select
              placeholder="Choose a Report Type"
              // options={ReportType}
              options={
                dynamic && dynamic == true
                  ? StandaloneReport()
                  : this.state.asmApprovalRequired == true
                  ? ReportType
                  : reportFilterType
              }
              className="download-box"
              onChange={this.handleDistributorChange}
              value={reportName}
            />
          </div>
          <div className="daterange mt-6">
            <p className="select-range">Select Range :</p>
            <Space direction="vertical" size={12}>
              <RangePicker
                className="ant-picker"
                onChange={this.selectDateRange}
                allowClear={true}
                disabledDate={this.customeDisableDates}
                onCalendarChange={this.onCalendarChange} 
                // defaultValue={[
                //   moment(todays, dateFormat),
                //   moment(todays, dateFormat),
                // ]}
                // value={customDate}
              />
            </Space>
          </div>
          <div className="col col-md-4 my-download-button">
            <a className="downloadButton" onClick={this.checkFormValid}>
              <span>
                Download <i className="fa fa-download"></i>
              </span>
            </a>
          </div>
        </Spin>
        {/* </div> */}
      </div>
    );
  }
}

export default DownloadReport;
